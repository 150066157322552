<template>
  <span class="mr-3">
    <div
      v-for="i in 4"
      :class="{
        'progress-bar-loaded': isLoaded(i),
        'progress-bar-overloaded': isOverLoaded(),
        'progress-bar-loading': isLoading(i),
      }"
      :key="i"
      class="square"
    ></div>
  </span>
</template>

<script>
export default {
  name: "progress-bar-loading",
  data() {
    return {
      opacity: 1,
    };
  },
  props: ["total_used", "total_capacity"],
  computed: {
    opacityStyle() {
      return { opacity: this.opacity };
    },
    percentage() {
      return Math.ceil(
        (parseFloat(this.total_used) / parseFloat(this.total_capacity)) * 100
      );
    },
    portion() {
      return parseFloat(this.total_capacity) / 4;
    },
  },
  methods: {
    isLoading(i) {
      if (i <= 4 && i > 1) {
        if (this.portion * i > parseFloat(this.total_used)) {
          if (this.portion * (i - 1) < parseFloat(this.total_used)) {
            this.opacity = (this.portion * i) / parseFloat(this.total_used);
            return 1;
          } else return 0;
        }
      } else if (i == 1) {
        if (this.portion * i > parseFloat(this.total_used)) {
          this.opacity = (this.portion * i) / parseFloat(this.total_used);
          return 1;
        } else return 0;
      } else return 0;
    },
    isLoaded(i) {
      if (i <= 4) {
        let capacity = this.portion * i;
        if (capacity < parseFloat(this.total_used)) {
          this.opacity = 1;

          return true;
        } else return false;
      }
    },
    isOverLoaded() {
      if (this.total_capacity < parseFloat(this.total_used)) {
        this.opacity = 1;
        return true;
      } else return 0;
    },
  },
};
</script>

<style>
.square {
  width: 10px;
  height: 10px;
  background-color: #fff;
  display: inline-block;
  margin-left: 5px;
  border: 1px solid #c7d9e2;
}

.square-hidden {
  display: none;
}

.progress-bar-loaded {
  border: 1px solid mediumseagreen;
  background-color: mediumseagreen;
}
.progress-bar-loading {
  border: 1px solid greenyellow;
  background-color: greenyellow;
}

.progress-bar-overloaded {
  background-color: indianred;
  border: 1px solid indianred;
}
</style>
